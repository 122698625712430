import React, { useState } from 'react'
import { Icon } from '../../config/icons'
import { siteConfi } from '../../config/siteConfi'
import { Link } from 'gatsby'
import { useVisitorTracker } from '../../hooks/use-tracker-visiter'

const MobileNav = () => {
   const [showMenu, setShowMenu] = useState(false)
   return (
      <div className='mobile-menu'>
         <button className='fixed top-[20px] right-[30px] h-[70px] w-[70px] rounded-full bg-black flex items-center justify-center z-[99999]' onClick={() => setShowMenu(prev => !prev)}>
            <Icon.bars width={20} className='text-white' />
         </button>
         <div className={`fixed inset-0 z-50 bg-black flex items-center flex-col justify-center pb-[50px] clip-bg ${showMenu ? 'active-menu' : ''} `}>
            {siteConfi.navLinks.map((item, index) => (
               <Link to={item.href} className='text-3xl pb-8 text-white' key={index} onClick={() => { setShowMenu(false) }}>{item.title}</Link>
            ))}
         </div>

      </div>
   )
}

export default MobileNav
